<template>
  <div
      class="review-notes-field field is-horizontal"
  >
    <div class="field-label" style="flex-grow:2;">
      <label class="query-field__label label is-danger">
        <span class="query-field__label-text">Notes</span>
      </label>
      <p v-if="error" class="help is-danger" style="text-align:left;">
        {{ error }}
      </p>
    </div>
    <div class="field-body">
          <textarea
              v-bind:value="value"
              v-on:input="$emit('input', $event.target.value)"
              :rows="rows"
              class="input is-small"
              v-bind:class="{'is-danger': error}"
          />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ""
    },
    error: {
      types: String,
    }
  },

  computed: {
    rows: function () {
      let lines = this.value.split(/\r\n|\r|\n/).length;

      return Math.max(5, lines)
    }
  },
}
</script>

<style lang="sass">
@import "../../node_modules/bulma/sass/utilities/_all.sass"

.review-notes-field
  border-bottom: solid 1px #eee
  margin: 0 !important
  padding: 0.5rem !important

  &:hover
    background-color: #fafafa

  textarea
    height: auto
</style>
